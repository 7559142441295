@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.boot-camps {
  background: light.$background-secondary-default;
  height: 100%;

  &__title {
    color: light.$on-surface-primary-default;
  }

  &__container {
    margin: 0 auto;
    width: 100%;
    max-width: 1144px; // NOTE: This width should match with the list width
    padding-left: spacing.$s;
    padding-right: spacing.$s;
  }

  &__container-inner {
    max-width: 1000px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header {
    padding-top: spacing.$l;
    padding-bottom: spacing.$m;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: spacing.$m;
    margin-bottom: spacing.$m;
  }

  &__top-section {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
    width: 100%;
    margin-bottom: spacing.$s;
  }

  &__pagination {
    margin: 0 spacing.$m;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
    margin-top: spacing.$xl;
    margin-bottom: spacing.$xxl;
    flex: 1;
  }

  &__additional-links {
    background-color: light.$surface-primary-default;
    border-radius: corner-radius.$m;
  }

  &__additional-link {
    display: flex;
    align-items: center;
    padding-left: spacing.$m;
    gap: spacing.$s;
    text-decoration: none;

    &:hover {
      background-color: light.$surface-primary-hover;
      border-radius: corner-radius.$m;
    }
  }

  &__additional-link-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: spacing.$s;
    padding-right: spacing.$m;
  }
}
